<template>
    <div id="content">
        <a href="https://satoshibles.com" id="logo" target="_blank"><img alt="ARTificial Satoshi" src="../images/logo.png"></a>
        <div class="mint-info">
            <div class="text">
                <p>The future is now. With the power of DALL·E 2 by OpenAI, we have created a collection of Satoshi artwork, fully generated by artificial intelligence. There are 1000 NFTs in the collection, each one unique and fun! Yes, we do have a giant space octopus version of Satoshi - who really knows?!</p>
                <p>To help promote the idea that NFTs can be used for social good, 50% of all mint proceeds will be donated to the
                    <a href="https://www.builtwithbitcoin.org" target="_blank">Built With Bitcoin foundation.</a></p>
                <p>The results produced by DALL·E 2 are stunning, it's amazing to think no human was involved in the generation of these unique pieces of art!</p>
            </div>
            <p><a href="https://create.gamma.io/collection/artificial-satoshi" class="button large primary" target="_blank">Mint Now on Gamma</a></p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
    components: {}
};
</script>

<style lang="scss">
@import "../css/app";
</style>